<template>
  <div class="common-bg page-container">
    <van-nav-bar :title="title" left-arrow @click-left="onClickLeft" />
    <van-form @submit="onSubmit">
      <van-cell-group inset>
        <van-field
          v-model="form.mobile"
          label="收款手机号"
          placeholder="收款手机号"
          :rules="[{ required: true, message: '请输入收款手机号' }]"
        />
        <van-field
          v-model="form.num"
          label="转赠数量"
          placeholder="转赠数量"
          :rules="[{ required: true, message: '请输入转赠数量' }]"
        />
		<van-field
		  v-model="password"
		  type="password"
		  maxlength="6"
		  name="password"
		  label="支付密码"
		  placeholder="请输入支付密码"
		  readonly
		  clickable
		  @click="clickField(1)"
		/>
      </van-cell-group>
      <div style="margin: 16px">
        <van-button round block type="primary" native-type="submit">
          立即转赠
        </van-button>
      </div>
    </van-form>
		<van-number-keyboard
		  v-model="password"
		  :show="show1"
		  random-key-order
		  :maxlength="6"
		  @blur="show1 = false"
		/>
  </div>

</template>

<script>
import {
  getUserProductList,
  getProductDetail,
  accountTransfer,
  checkPayPwdIsCorrect,
  getScoreType,
} from '@/utils/api.js'
export default {
  data() {
    return {
		value: '',
		show1: false,
		password: '',
      title: '幸运积分互转',
      form: {},
      showPicker: false,
      currencyInfo: {
        num: 0,
        money: '0.00',
        score_type: 1,
      }
    }
  },
  created() {
   this.getColumns()
  },
   methods: {
	  clickField(type) {
	    if(type === 1) {
	      this.show1 = true
	      this.password = ''
	    }
		},
    onSubmit() {
      let { score_type, id } = this.currencyInfo
      let { num, mobile } = this.form
      let form = {
        num: Number(num),
        mobile,
        score_type,
        id
      }
	  if (this.password === '') {
	    this.$toast('请输入支付密码')
	    return
	  }
	  const param = { payPassword: this.password }
	  checkPayPwdIsCorrect(param).then(res => {
	    if (res.code === 200) {
	      this.$toast.success({
	        message: res.message,
	        duration: 1500,
	        onClose: () => {
	          this.onClickLeft()
	        }
	      })
	    } else {
	      this.$toast(res.message)
	    }
	  }),
      accountTransfer(form).then((res) => {
        this.$toast(res.message)
        this.onClickLeft()
      })
    },


    onCancel() {
      this.showPicker = false
    },
  getColumns() {
      getScoreType().then((res) => {
        this.columns = res.data
        this.currencyInfo = {
        score_type,
          id
        }
      })
    },
    onClickLeft() {
      this.$router.go(-1)
    }
  }
}
</script>

<style lang="scss" scoped>
.page-container {
  ::v-deep .van-field__control {
    text-align: right;
  }
}
</style>
